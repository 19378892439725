import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { clsx } from 'clsx';
import axios from 'axios';

const Concerts = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const sheetId = '1U11scS4TGvZxTRYSo3etntJpCBpQSE_wkCyL0QaMvcE';
    const apiKey = 'AIzaSyCPC4KLDHzqMIm0DYIyRMzYPqzt8u9zta8';
    const range = 'concerts!A2:D50'; // Plage complète avec les concerts

    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}/values/${range}?key=${apiKey}`
        );

        const sheetData = response.data.values.map(([date, titre, ville, pays]) => {
          if(!date){
            return null;
          }
          return ({
            date: DateTime.fromFormat(date, 'dd/MM/yyyy'),
            titre,
            ville,
            pays,
          });
        });
        setData(sheetData);
      } catch (error) {
        console.error('Erreur lors de la récupération des données', error);
      }
    };

    fetchData();
  }, []);


  return (
    <div className="dateTables-parent">
      <table className="dateTables">
        {data
          .filter((d) => d !== null)
          .sort((d1, d2) => d2.date - d1.date)
          .map(({ date, titre, ville, pays }, index) => (
            <tr key={index} className={clsx(date < DateTime.local() && 'dateTables-passed')}>
              <td>{date.toFormat('dd/MM/yyyy')}</td>
              <td>{titre}</td>
              <td>{ville}</td>
              <td>{pays}</td>
            </tr>
          ))}
      </table>
    </div>
  );
};

export default Concerts;
