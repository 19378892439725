import React, { useState } from 'react';
import { clsx } from 'clsx';

const Desc = () => {
  const [lang, setLang] = useState('fr');
  return (
    <>
      { lang === 'fr' ? (
        <div className="page-accueil-part2-fr">
          <p>
            LGMX déconstruit l’électro, la techno, la house, la psytrance jusqu'à la quantité insécable de groove
            accessible à nos sens, en isole chaque briquette énergétique élémentaire, réagence l'ensemble comme un
            bonobo sous acide assemblerait un puzzle, et, des acouphènes pour toutes médailles, offre ainsi à l’humanité écrasée par les machines, un râle bruyant, sauvage et brutal d'animal blessé.
          </p>

          <p>
            Dans la droite ligne de Too Many Zooz, Meute ou encore TechnoBrass, LGMX est un orchestre acoustique mais
            électromoteur susceptible d’arracher les corps à la gravité et de propulser l’humain devenu pure énergie aux confins de la bringue pour y fusionner avec les forces du cosmos tout entier..
          </p>
        </div>
      ) : (
        <div className="page-accueil-part2-en">
          <p>LGMX disassembles Electro, Techno, House music, and Psytrance down to the unbreakable groove quantity perceivable by human senses, isolates each energetic elementary particle, stirs it up like an acid tripping bonobo would put a puzzle together, and offers to humankind, flattened under the machines, the wild, hoarse, brutal groan of a wounded animal.</p>

          <p>Right in the Too Many Zooz, Meute or Technobrass lineage, LGMX is an acoustic and lightning inducing orchestra, able to unbind bodies from the pull of gravity and propel humanity turned into pure energy past the boundaries of the party to fuse into the very forces of the whole cosmos</p>
        </div>
      )}

      <button className={clsx(lang === 'en' && 'en')} onClick={() => setLang(lang === 'fr' ? 'en' : 'fr')}>&nbsp;</button>
    </>
  );
};

export default Desc;
