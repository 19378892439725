import React, { useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import Elevation from '../../audio/LGMX-Elevation.mp3';
import Trancelation from '../../audio/LGMX-Trancelation.mp3';
import GopnikMazurka from '../../audio/LGMX-GopnikMazurka.mp3';
import SmallTalk from '../../audio/LGMX-SmallTalk.mp3';
import Darius from '../../audio/LGMX-Darius.mp3';
import MovementOfWhales from '../../audio/LGMX-MovementOfWhales.mp3';

const AudioPlayer = () => {
  const [src, setSrc] = useState(null);
  const player = useRef();

  useEffect(() => {
    if (player.current) {
      if (src) {
        player.current.play();
      } else {
        player.current.stop();
      }
    }
  }, src);

  const clickOnLine = (newSrc) => {
    if (newSrc === src) {
      setSrc(null);
    } else {
      setSrc(newSrc);
    }
  };

  return (
    <>
      <button type="button" onClick={() => clickOnLine(Trancelation)} className={clsx(src === Trancelation && 'isPlaying')}>
        <div>LGMX - Trancelation</div>
        <div className="audioContainer-progress" style={{ width: '0%' }}>&nbsp;</div>
      </button>
      <button type="button" onClick={() => clickOnLine(Elevation)} className={clsx(src === Elevation && 'isPlaying')}>
        <div>LGMX - Elevation</div>
        <div className="audioContainer-progress" style={{ width: '0%' }}>&nbsp;</div>
      </button>
      <button type="button" onClick={() => clickOnLine(GopnikMazurka)} className={clsx(src === GopnikMazurka && 'isPlaying')}>
        <div>LGMX - Gopnik Mazurka</div>
        <div className="audioContainer-progress" style={{ width: '0%' }}>&nbsp;</div>
      </button>
      <button type="button" onClick={() => clickOnLine(Darius)} className={clsx(src === Darius && 'isPlaying')}>
        <div>LGMX - Darius (Live)</div>
        <div className="audioContainer-progress" style={{ width: '0%' }}>&nbsp;</div>
      </button>
      <button type="button" onClick={() => clickOnLine(MovementOfWhales)} className={clsx(src === MovementOfWhales && 'isPlaying')}>
        <div>LGMX - Movement Of Whales</div>
        <div className="audioContainer-progress" style={{ width: '0%' }}>&nbsp;</div>
      </button>

      { src && (
        <audio id="audioContainer-player" ref={player}>
          <source src={src} type="audio/mpeg" />
        </audio>
      )}

    </>
  );
};

export default AudioPlayer;
