import React, { useEffect, useRef } from 'react';
import Logo from './Logo';
import Concerts from './Concerts';
import AudioPlayer from './AudioPlayer';
import VideoCarousel from './VideoCarousel';
import Gallery from './Gallery';
import videoFallback from '../../video/videoFallback.jpg';
import video720p from '../../video/high.mp4';
import youtube from '../../img/youtubeBig.svg';
import spotify from '../../img/spotify.svg';
import deezer from '../../img/deezer.svg';
import bandcamp from '../../img/bandcamp.svg';
import Social from './Social';
import Desc from "./Desc";

const Accueil = () => {
  const refParticle = useRef();
  const scrollTo = (id) => {
    const section = document.querySelector(id);
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() => {
    if (refParticle.current) {
      particlesJS('particles-js', {
        particles: {
          number: {
            value: 6,
          },
          color: {
            value: ['#00FFFF'],
          },
          shape: {
            type: 'image',
            image: {
              src: '../particleJS/test2.png',
              width: 20,
              height: 20,
            },
          },
          opacity: {
            value: 1,
            random: true,
            anim: {
              enable: true,
              speed: 0.5,
              opacity_min: 1,
              sync: false,
            },
          },
          size: {
            value: 200,
            random: true,
            anim: {
              enable: true,
              speed: 100,
              size_min: 1,
              sync: false,
            },
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: '#00FFFF',
            opacity: 0,
            width: 4,
          },
          move: {
            enable: true,
            speed: 20,
            direction: 'top-right',
            random: false,

            straight: true,
            out_mode: 'out',

            bounce: false,
            // "attract": {
            //   "enable": true,
            //   "rotateX": 10,
            //   "rotateY": 10
            // }
          },
        },
        interactivity: {
          detect_on: 'canvas',
          events: {
            onhover: {
              enable: false,
              mode: ['grab', 'bubble'],
            },
            onclick: {
              enable: false,
              mode: 'repulse',
            },
            resize: true,
          },
        },
        retina_detect: true,
      });
    }
  }, []);

  return (
    <>

      <div id="page-accueil">

        <header>
          <nav>
            <ul>
              <li>
                <button onClick={() => scrollTo('#page-accueil-part2')}>Accueil</button>
              </li>
              <li>
                <button onClick={() => scrollTo('#page-accueil-part3')}>Medias</button>
              </li>
              <li>
                <button onClick={() => scrollTo('#page-accueil-part4')}>Dates</button>
              </li>
              <li>
                <button onClick={() => scrollTo('#page-accueil-part5')}>Contact</button>
              </li>
            </ul>
          </nav>

          <Social />

        </header>

        <section id="page-accueil-part1">
          <div>
            <Logo />

            <h1>fanfare psychoactive <i />electro-stimulante ...</h1>
          </div>

          <div className="videoContainer">
            <div className="videoContainer-overlay" />
            <img src={videoFallback} alt="LGMX jouer de la fanfare électro à Lyon" />
            <video loop playsInline autoPlay muted>
              <source src={video720p} type="video/mp4" />
            </video>
          </div>
          <div className="gradienBot" />
        </section>

        <section id="page-accueil-part2">
          <hr />
          <Desc />

          <hr />
        </section>

        <section id="page-accueil-part3">
          <div className="gradienTop" />
          <div className="gradienBot" />
          <div id="particles-js" ref={refParticle} />
          <div>
            <h2 className="stdTitle"><span>M</span>édias</h2>

            <div>
              <VideoCarousel />
            </div>

            <Gallery />

            <div className="audioContainer">
              <h2 className="stdTitle"><span>E</span>coutez</h2>
              <AudioPlayer />

              <div className="audioContainer-liens">
                <a href="https://www.youtube.com/@lgmx?sub_confirmation=1" target="_blank" rel="noopener noreferrer">
                  <img src={youtube} alt="Ecoutez sur youtube" />
                </a>
                 <a href="https://www.deezer.com/fr/artist/153180362" target="_blank" rel="noopener"><img src={deezer} alt="Ecoutez sur deezer" /></a>
                <a href="https://open.spotify.com/artist/4PB0dSRjj5awNOTuLHP32P" target="_blank" rel="noopener noreferrer"><img src={spotify} alt="Ecoutez sur youtube" /></a>
                <a href="https://lgmx.bandcamp.com/releases" target="_blank" rel="noopener noreferrer"><img src={bandcamp} alt="Ecoutez sur bandcamp" /></a>
              </div>
            </div>
          </div>
        </section>
        <section id="page-accueil-part4">

          <h2 className="stdTitle"><span>D</span>ates</h2>

          <Concerts />

        </section>
        <section id="page-accueil-part5">
          <div>
            <div>
              <h3>Contact / Booking : <small>contact@lgmx.fr</small></h3>
            </div>
            <div>
              <h3>Social</h3>
              <Social />

            </div>
          </div>
        </section>

        <footer>
          <nav>
            <ul>
              <li><a href="">copyright</a></li>
              <li><a href="">mentions légales</a></li>
            </ul>
          </nav>
        </footer>

      </div>
    </>
  );
};

export default Accueil;
