import React from 'react';
import facebook from '../../img/facebook.svg';
import instagram from '../../img/instagram.svg';
import youtube from '../../img/youtube.svg';
import spotify from '../../img/spotifySmall.svg';
import deezer from '../../img/deezerSmall.svg';

const Social = () => (
  <nav className="socialMenu">
    <ul>
      <li><a href="https://www.facebook.com/LGMXtechnofanfare/" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="facebook" /></a></li>
      <li><a href="https://www.instagram.com/_lgmx_/" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="instagram" /></a></li>
      <li><a href="https://www.youtube.com/@lgmx?sub_confirmation=1" target="_blank" rel="noopener noreferrer"><img src={youtube} alt="youtube" /></a></li>
      <li><a href="https://www.deezer.com/fr/artist/153180362" target="_blank" rel="noopener noreferrer"><img src={deezer} alt="deezer" /></a></li>
      <li><a href="https://open.spotify.com/artist/4PB0dSRjj5awNOTuLHP32P" target="_blank" rel="noopener noreferrer"><img src={spotify} alt="spotify" /></a></li>
    </ul>
  </nav>
);

export default Social;
