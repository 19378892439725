import "../less/design.less";


import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';

import App from './screens/App';

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);



import "./pages/accueil.js";