import React from 'react';

const Logo = () => {
 return <svg version="1.1" id="Calque_1" xx="0px" y="0px"
             viewBox="-50 -50 1100 770.7" style={{enableBackground: "new -50 -50 1200 700."}} >

   <title>lgmx-logo</title>
   <defs>
		<filter id="sofGlow2" height="300%" width="300%" x="-75%" y="-75%">
			<feMorphology operator="dilate" radius="4" in="SourceAlpha" result="thicken"/>
      <feGaussianBlur in="thicken" stdDeviation="20" result="blurred" id="gausblur"/>
      <feFlood floodColor="#cb10fa" result="glowColor"/>
      <feComposite in="glowColor" in2="blurred" operator="in" result="softGlow_colored"/>
      <feComponentTransfer in="softGlow_colored" result="softGlow_colored2">
				<feFuncA type="linear" slope="0.5"/>
			</feComponentTransfer>


      <feMerge>
				<feMergeNode in="softGlow_colored2"/>
        <feMergeNode in="SourceGraphic"/>
			</feMerge>
		</filter>


     <filter id='innershadow'>

			<feOffset
        dx='0'
        dy='0'
      />
       <feGaussianBlur
         stdDeviation='8'
         result='offset-blur'
       />
       <feComposite
         operator='out'
         in='SourceGraphic'
         in2='offset-blur'
         result='inverse'
       />

       <feFlood
         floodColor='#00FFFF'
         floodOpacity='1'
         result='color'
       />

       <feComposite
         operator='in'
         in='color'
         in2='inverse'
         result='shadow'
       />

       <feComposite
         operator='over'
         in='shadow'
         in2='SourceGraphic'
       />
		</filter>

	</defs>


   <g id="Dessin" filter="url(#innershadow)">
		<g>
			<g filter="url(#sofGlow2)">>
        <g>
						<path style={{fill: "#FFF"}} d="M219.1,523.1H36c-14.3,0-26-11.6-26-25.9V305.9c0-14.3,11.7-25.9,26-25.9h49.3c14.3,0,25.9,11.6,25.9,25.9
					v108.1c0,6,4.9,10.9,10.9,10.9h77.2c14.3,0,25.9,11.6,25.9,25.9v11.7c0,4.1-3.4,7.5-7.5,7.5s-7.5-3.4-7.5-7.5v-11.7
					c0-6-4.9-10.9-10.9-10.9h-77.1c-14.3,0-25.9-11.6-25.9-25.9V305.9c0-6-4.9-10.9-10.9-10.9H36c-6,0-11,4.8-11,10.9c0,0,0,0,0,0.1
					v191.3c0,6,4.9,10.9,10.9,10.9c0,0,0,0,0.1,0h183.1c2.9,0,5.7-1.1,7.7-3.2L374,357.8c2.9-2.9,7.7-2.9,10.6,0s2.9,7.7,0,10.6
					L237.4,515.5C232.5,520.4,225.9,523.1,219.1,523.1z"/>

          <path style={{fill: "#FFF"}} d="M365.7,322.4c-43,0-83.3-16-113.3-45c-30.4-29.3-47.1-68.9-47.1-111.6c0-4.1,3.4-7.5,7.5-7.5
					s7.5,3.4,7.5,7.5c0,79.4,63.9,141.6,145.4,141.6c37.7,0.1,73.9-14.9,100.4-41.7c0.9-0.9,1.7-1.8,2.6-2.7
					c1.8-1.9,2.8-4.5,2.8-7.1v-74c0-5.7-4.6-10.4-10.4-10.4h-88.5c-4.1,0-7.5-3.4-7.5-7.5s3.4-7.5,7.5-7.5h88.5
					c14,0,25.3,11.4,25.4,25.4v74c0,6.5-2.5,12.7-6.9,17.4c-0.9,1-1.9,2-2.9,3C447.4,305.9,407.4,322.5,365.7,322.4z"/>

          <path style={{fill: "#FFF"}} d="M77.3,246.6c-4.1,0-7.5-3.4-7.5-7.5c0-2,0.8-3.9,2.2-5.3L251.9,54.6c30.1-29.3,70.5-45.4,113.7-45.4
					c33.2,0,65.5,10.6,92.2,30.2c11.3,8.2,13.8,24.1,5.5,35.4c-0.8,1.1-1.6,2.1-2.5,3L429,109.7c-8.6,8.6-22.1,9.9-32.1,3.1
					c-10.2-6.8-22.1-10.5-34.4-10.4c-34.5,0-62.5,28.8-62.5,64.2s28,64.5,62.5,64.5c13.4,0,26.5-4.4,37.2-12.5v-15.2
					c0-4.1,3.4-7.5,7.5-7.5s7.5,3.4,7.5,7.5v18.9c0,2.2-1,4.3-2.7,5.8c-13.9,11.6-31.4,18-49.5,18c-42.7,0-77.5-35.7-77.5-79.5
					s34.8-79.2,77.5-79.2c15.2-0.1,30.2,4.5,42.8,13c4.1,2.8,9.6,2.3,13.1-1.3l31.8-31.8c2.1-2.1,3.2-5.1,3-8.2
					c-0.2-3-1.7-5.8-4.2-7.5c-24.2-17.8-53.4-27.4-83.4-27.4c-39.3,0-76,14.6-103.2,41.1l-179.9,179
					C81.2,245.7,79.3,246.5,77.3,246.6z"/>

          <path style={{fill: "#FFF"}} d="M656.6,617.6c-4.1,0-7.5-3.4-7.5-7.5V480.9c0-6.1-4.9-11-11-11h-41.4c-2.9,0-5.7,1.2-7.8,3.2L524,537.9
					c-2.9,2.9-7.7,2.9-10.6,0s-2.9-7.7,0-10.6l64.9-64.9c4.9-4.9,11.5-7.6,18.4-7.6H638c14.4,0,26,11.7,26,26v129.3
					C664.1,614.2,660.7,617.6,656.6,617.6z"/>

          <path style={{fill: "#FFF"}} d="M648.9,686H592c-14.3,0-26-11.7-26-26v-69.3l-31,31c-10.2,10.2-26.7,10.2-36.8,0l-31-31V660
					c0,14.3-11.7,26-26,26h-46.1c-14.3,0-26-11.7-26-26V480.9c0-14.4,11.6-26,26-26h83.1c6.9,0,13.5,2.7,18.4,7.6l11.5,11.5
					c2.9,3,2.8,7.7-0.2,10.6c-2.9,2.8-7.5,2.8-10.4,0L486,473c-2.1-2.1-4.9-3.2-7.8-3.2h-83.1c-6.1,0-11,5-11,11V660
					c0,6.1,4.9,11,11,11l0,0h46c6.1,0,11-4.9,11-11l0,0v-73.7c0-7.3,5.9-13.2,13.2-13.2c3.5,0,6.9,1.4,9.4,3.9l34,34
					c4.3,4.3,11.3,4.3,15.6,0l34-34c5.1-5.2,13.5-5.3,18.7-0.2c2.5,2.5,4,5.9,3.9,9.5V660c0,6.1,4.9,11,11,11h56.9
					c2.9,0,5.7-1.2,7.8-3.2l226.7-226.7c3.1-2.8,7.8-2.6,10.6,0.5c2.6,2.9,2.6,7.2,0,10.1L667.3,678.4
					C662.5,683.3,655.8,686,648.9,686z"/>

          <path style={{fill: "#FFF"}} d="M435.5,421.7c-4.1,0-7.5-3.4-7.5-7.5c0-2,0.8-3.9,2.2-5.3l254.2-254.2c4.9-4.9,11.5-7.6,18.3-7.6h73.4
					c8.2,0,15.9,3.9,20.8,10.4l0.1,0.1l25.5,35.2l26.5-35.3c4.9-6.5,12.6-10.3,20.7-10.4h70.9c7.3,0,13.2,5.9,13.2,13.2
					c0,2.8-0.9,5.5-2.5,7.8l-71.6,97.8c-2.8,3.8-2.8,9,0,12.8l71.8,99.7c4.2,5.9,2.9,14.1-3,18.4c-2.2,1.6-4.9,2.5-7.7,2.5H869
					c-8.3,0-16.1-4-21-10.8c-0.1-0.1-0.2-0.3-0.3-0.4l-9.6-15.3c-2.2-3.5-1.2-8.1,2.3-10.4c3.5-2.2,8.1-1.2,10.4,2.4l0,0l9.5,15
					c2.1,2.7,5.3,4.4,8.7,4.4h68.2l-69.7-96.8c-6.6-9.1-6.5-21.4,0.1-30.5l69.4-94.9h-67.3c-3.4,0-6.7,1.6-8.7,4.4l-28,37.2
					c-2.5,3.3-6.4,5.3-10.6,5.3l0,0c-4.2,0-8.1-2-10.6-5.4l-27-37.2c-2.1-2.7-5.3-4.3-8.7-4.4h-73.4c-2.9,0-5.7,1.1-7.7,3.2
					L440.8,419.5C439.4,420.9,437.5,421.7,435.5,421.7z M821,194.8L821,194.8z"/>

          <path style={{fill: "#FFF"}} d="M760.9,399.3h-66.8c-7.3,0-13.2-5.9-13.2-13.2c0-2.8,0.9-5.4,2.5-7.7l71.8-99.7c2.8-3.8,2.8-9,0-12.8
					l-0.1-0.1l-41.3-58.1c-2.1-3.6-0.9-8.2,2.7-10.3c3.1-1.8,7.1-1.2,9.5,1.6l41.3,58.1c6.6,9,6.7,21.3,0.1,30.4l-69.7,96.8h63.2
					c3.5,0,6.8-1.7,8.9-4.5l47.3-65.6c2.3-3.4,7-4.3,10.4-2s4.3,7,2,10.4c-0.1,0.1-0.1,0.2-0.2,0.3L782,388.5
					C777.1,395.3,769.3,399.3,760.9,399.3z"/>
					</g>
			</g>
		</g>
	</g>
</svg>
};

export default Logo;
