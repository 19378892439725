import React from 'react';
import Accueil from './Accueil';
import {BrowserRouter} from "react-router-dom";
import {Routes, Route} from "react-router";

const GotoUrl = ({url, texte}) => {
  window.location.href = url;
  return <div style={{textAlign: "center", padding: "4rem"}}>{texte}</div>
}


const App = () => <BrowserRouter>
  <Routes>
    <Route path="/" element={ <Accueil /> } />
     <Route path="merch" element={<GotoUrl texte="Chargement du merch" url="https://lgmx.bandcamp.com/merch"/>} />
     <Route path="photos" element={<GotoUrl texte="Chargement du lien des photos" url="https://e.pcloud.com/#page=puplink&code=skcZ7FtcC4xX6Su6Ao26dnfyJ4d9hYU7"/>} />
     <Route path="ft" element={<GotoUrl texte="Chargement de la fiche technique" url="https://drive.google.com/drive/folders/1ahRJc6xyzip5cfbhmUA9iDP8rHyt5jsf"/>} />
     <Route path="ft-en" element={<GotoUrl texte="Loading technical specs" url="https://drive.google.com/drive/folders/1ahRJc6xyzip5cfbhmUA9iDP8rHyt5jsf"/>} />
  </Routes>
</BrowserRouter>;


export default App;
